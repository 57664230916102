import { StoreType } from './store-types'

export const initialState: StoreType = {
  summary: {
    helpLink: '',
    licenses: {
      planLicenses: 0,
      workLicenses: 0,
      reviewLicenses: 0,
      requestLicenses: 0,
      standardLicenses: 0,
      lightLicenses: 0,
      freeLicenses: 0,
      planLicensesUsed: 0,
      workLicensesUsed: 0,
      reviewLicensesUsed: 0,
      requestLicensesUsed: 0,
      standardLicensesUsed: 0,
      lightLicensesUsed: 0,
      freeLicensesUsed: 0,
      reviewLicensesUnlimited: false,
      requestLicensesUnlimited: false,
      freeLicensesUnlimited: false,
      edition: '',
      expirationDate: 0,
      showProofLicenseCount: false,
      usersUsage: 0,
      usersLimit: 0,
      showAlignLicenseCount: false,
      showAlignLicensesAsUnlimited: false,
      alignUsersUsage: 0,
      alignUsersLimit: 0,
      licenseApprovalLimit: '',
    },
    loadingState: {
      isLoading: false,
      loadingError: null,
    },
    manageAccess: {
      isGroupAdmin: false,
      isPackageSupportsManage: false,
    },
  },
  groups: [],
  activeRow: {
    activeRowGroupID: null,
    highlightedRowGroupID: null,
    errors: {},
  },
  customer: {
    isIMSEnabled: false,
    licenseTypeVersion: null,
  },
  manageGroupsDialog: {
    dialogState: false,
    saveIsActive: false,
    cancelSearchIsActive: false,
    dialogGroupItems: [],
    filteredGroupItems: [],
    searchInputValue: '',
  },
}
